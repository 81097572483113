import { Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError as observableThrowError, Observable, EMPTY } from "rxjs";

export class LoggerConfig {
  consoleLogLevel?: string;
  serverLoggingUrl?: string;
  serverLogLevel?: string;
  httpParams?: any;
  userParams?: any;
}

enum Levels {
  "ALL",
  "TRACE",
  "DEBUG",
  "INFO",
  "LOG",
  "WARN",
  "ERROR",
  "FATAL",
  "OFF"
}

@Injectable({
  providedIn: "root"
})
export class RossLogger {
  tzoffset = new Date().getTimezoneOffset() * 60000;
  localISOTime = new Date(Date.now() - this.tzoffset)
    .toISOString()
    .slice(0, -1);

  constructor(
    private http: HttpClient,
    @Optional() private options: LoggerConfig
  ) {}

   trace(message?: any, ...optionalParams: any[]) {
    this._log("TRACE", true, message, ...optionalParams);
  }

   debug(message?: any, ...optionalParams: any[]) {
    this._log("DEBUG", true, message, ...optionalParams);
  }

   info(message?: any, ...optionalParams: any[]) {
    this._log("INFO", true, message, ...optionalParams);
  }

   log(message?: any, ...optionalParams: any[]) {
    this._log("LOG", true, message, ...optionalParams);
  }

   warn(message?: any, ...optionalParams: any[]) {
    this._log("WARN", true, message, ...optionalParams);
  }

   error(message?: any, ...optionalParams: any[]) {
    this._log("ERROR", true, message, ...optionalParams);
  }

   fatal(message?: any, ...optionalParams: any[]) {
    this._log("FATAL", true, message, ...optionalParams);
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    headers = headers.append("accept", "application/json");
    headers = headers.append("Access-Control-Allow-Origin", "*");
    headers = headers.append(
      "Access-Control-Allow-Methods",
      "GET, POST, PATCH, PUT, DELETE, OPTIONS"
    );
    headers = headers.append(
      "Access-Control-Allow-Headers",
      "Origin, Content-Type, X-Auth-Token"
    );
    headers = headers.append("Access-Control-Allow-Credentials", "true");
    for (let k in this.options.httpParams) {
      headers = headers.append(k, this.options.httpParams[k]);
    }
    return headers;
  }

  setHeaders(modifiedHeaders: any) {
    for (let k in this.options.httpParams) {
      this.options.httpParams[k] = modifiedHeaders[k];
    }
  }

  private _logOnServer(
    level: string,
    message: string,
    ...optionalParams: any[]
  ) {
    if (this.options.serverLoggingUrl) {
      if (
        (this.options.serverLogLevel &&
          Levels[<any>level] < Levels[<any>this.options.serverLogLevel]) ||
        (sessionStorage.getItem("USER_LOG") &&
          sessionStorage.getItem("USER_LOG") === "DEBUG")
      ) {
        return EMPTY;
      } else {
        let httpOptions = { headers: this.getHeaders() };
        return this.http
          .post(
            this.options.serverLoggingUrl,
            { level: level, message: message, additionalInfo: optionalParams },
            httpOptions
          )
          .pipe(catchError(this.handleError));
      }
    } else {
      return EMPTY;
    }
  }

  private _log(
    level: string,
    logOnServer: boolean,
    message?: any,
    ...optionalParams: any[]
  ): any {
    if (!message) {
      return;
    }
    let color;
    switch (level) {
      case "TRACE":
        color = "blue";
        break;
      case "DEBUG":
        color = "MediumSlateBlue";
        break;
      case "INFO":
        color = "DarkOrchid";
        break;
      case "LOG":
        color = "gray";
        break;
      case "WARN":
        color = "IndianRed";
        break;
      case "ERROR":
        color = "red";
        break;
      case "FATAL":
        color = "Brown";
        break;
      case "OFF":
      default:
        return;
    }

    if (
      (this.options.consoleLogLevel &&
        Levels[<any>level] >= Levels[<any>this.options.consoleLogLevel]) ||
      (sessionStorage.getItem("USER_LOG") &&
        Levels[<any>level] >= Levels[<any>sessionStorage.getItem("USER_LOG")])
    ) {
      console.log(
        `%c${this.localISOTime} [${level}] %c${message} ${optionalParams}`,
        `color:${color}`,
        "color:black"
      );
    }
    if (!logOnServer) {
      return;
    } else {
      this._logOnServer(level, message, optionalParams).subscribe();
    }
  }

  private handleError(error: any): Observable<any> {
    return observableThrowError(
      "FAILED TO LOG ON SERVER" + error.message || error
    );
  }
}
